.projectContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  flex: 1;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px;
  height: 500px;
  background-color: white;

  .loadingProgress {
    width: 100%;
    height: 100%;
    align-items: center !important;
    justify-content: center !important;
  }

  .projectContainerItem {
    flex: 1;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    white-space: initial;

    .projectTitle {
      font-weight: 800;
      font-size: large;
    }

    .projectSubtitle {
      font-weight: 700;
      font-size: normal;
    }

    .projectDesc {
      padding-left: 10px;
      white-space: pre-line;
    }

    .projectTech {
      padding-left: 28px;
      margin: 0px;
    }

    .projectRepo {
      position: absolute;
      bottom: 4px;
      right: 10px;
      height: fit-content;
      color: black;
      font-size: 40px !important;
    }
  }

  .projectDemoImage {
    width: 100%;
    height: 100%;
  }

  .projectSupplementContainer {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  #loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #loading-overlay::after {
    content: "Loading...";
    color: white;
    font-size: 20px;
  }
}
