.sidebarContainer {
  z-index: 1;
  max-width: 300px;
  height: 56px;
  min-height: 0px;
  color: hsla(0, 0%, 100%, 0.8);

  .background {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-image: url("../../assets/images/sidebar-2.jpg");
    position: sticky;
    z-index: 0;

    .topDivider {
      background: white;
      margin-left: 20px;
      margin-right: 20px;
      position: sticky;
      z-index: 5;
      border: none;
      height: 1px;
      right: 15px;
      color: rgba(180, 180, 180, 0.3); /* old IE */
      background-color: rgba(180, 180, 180, 0.3); /* Modern Browsers */
    }

    .backgroundFilter {
      background-color: #000;
      content: " ";
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0.8;
      width: 100%;
      height: 100%;
      z-index: 0;
    }

    .headerContainer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      min-height: 0px;
      z-index: 5;

      .headerText {
        color: white;
        min-height: 60px;
        display: flex;
        align-items: center;
        margin-top: 2px;
        margin-bottom: 2px;
        justify-content: center;
        position: sticky;
        flex: 1;
      }

      .drawerIconButtonContainer {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        width: fit-content;

        .drawerIconButton {
          color: white;
        }
      }
    }

    .content {
      overflow: auto;
      position: relative;
      color: hsla(0, 0%, 100%, 0.8);
      z-index: 2;
    }

    .sideBarItemContainer {
      overflow: none;
      white-space: nowrap;
      color: hsla(0, 0%, 100%, 0.8);
      line-height: 1.5em;

      .sideBarItem {
        text-decoration: none;
        display: flex;
        align-items: center;
        color: rgba(255, 255, 255, 0.8);
        width: 100%;

        .sideBarItemIcon {
          color: rgba(255, 255, 255, 0.8);
          margin-right: 15px;
          font-size: 30px;
          display: flex;
          width: fit-content;
          min-width: 24px;
        }
      }
    }
  }
}
