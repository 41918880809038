.widget {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;

  flex: 1;
  padding: 10px;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px;
  height: 100px;
  background-color: white;

  .corner {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    
    .left,
    .right {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .title {
        font-weight: bold;
        font-size: 14px;
        color: rgb(160, 160, 160);
      }

      .counter {
        font-size: 28px;
        font-weight: 300;
      }

      .link {
        width: max-content;
        font-size: 12px;
      }

      .percentage {
        display: flex;
        align-items: center;
        font-size: 14px;

        &.positive {
          color: green;
        }
        &.negative {
          color: red;
        }
      }
    }
  }
}
