@font-face {
  font-family: SFUIText;
  src: url(../assets/fonts/SFUIText-Regular.otf) format("opentype");
}

@font-face {
  font-family: SFUIDisplay;
  src: url(../assets/fonts/SFUIDisplay-Regular.otf) format("opentype");
}

@media only screen and (max-width: 800px) {
  .notShownInMobile {
    display: none !important;
  }
}

.bodyContainer {
  display: flex;
  flex-grow: 1;
  font-family: SFUIText SFUIDisplay;
  font-weight: 300;
  margin: 0px;
  background-color: #eee;
  height: 100vh;
  width: 100%;

  .webRightContainer {
    width: 100%;
    height: 100%;
    width: calc(100% - 260px);

    .contentContainer {
      flex-grow: 3;
      overflow: auto;
    }

    .cardContainer {
      background-color: white;
      display: flex;
      height: fit-content;
      margin: 0px;
      justify-content: flex-start;
      align-items: flex-start;
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      border-radius: 10px;
      flex-direction: column;
      overflow: auto;
    }
  }
}
