.profileContainer {
  padding: 20px;
  padding-top: 0px;
  overflow: hidden;
  height: 100%;

  .gridContainer {
    display: flex;
    max-width: 100%;
    flex-direction: column;

    .sectionTitle {
      background: linear-gradient(45deg, #553c9a, #ee4b2b);
      font-size: 25px;
      padding-top: 30px;
      font-weight: bold;
      -webkit-background-clip: text;
      margin-bottom: 10px;
      -webkit-text-fill-color: transparent;
    }

    .aboutLeftContainer {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .cardContainer {
      width: auto;
      height: fit-content;
      justify-content: center;
      align-items: center;
      text-align: flex-start;
      padding: 20px;
      flex-grow: 1;

      .cardContentContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
    }
  }
}
