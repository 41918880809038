.navbarContainer {
  height: 60px;
  background-color: #eee;

  .search {
    display: flex;
    align-items: center;
    border: 0.5px solid lightgray;
    padding: 3px;

    input {
      border: none;
      outline: none;
      background: transparent;

      &::placeholder {
        font-size: 12px;
      }
    }
  }

  .items {
    display: flex;
    align-items: center;
    flex-direction: row;

    .item {
      display: flex;
      font-size: 20px;
      align-items: center;
      margin-right: 20px;
      position: relative;

      .avatar {
        width: 30px;
        height: 30px;
        border-radius: 25%;
        border: 1px solid #555;
      }

      .counter {
        width: 15px;
        height: 15px;
        background-color: red;
        border-radius: 50%;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        font-weight: bold;
        position: absolute;
        top: -5px;
        right: -5px;
      }
    }
  }
}
