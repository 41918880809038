.JobExperienceContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;

  .top {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .companyLogoContainer::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid black;
      border-radius: 5px;
      pointer-events: none;
      z-index: 2;
    }

    .companyLogoContainer {
      width: 50px;
      height: 50px;
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .companyBackground {
        width: max-content;
        height: 100%;

        .companyLogo {
          width: 100%;
          height: 100%;
          border-radius: inherit;
          object-fit: contain;
          z-index: 99;
        }
      }
    }

    .jobDetailContainer {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-around;

      .jobTitle {
        font-weight: bold;
      }

      .companyName {
        font-weight: 400;
      }

      .companyName {
        font-weight: 400;
      }
    }
  }
  .bottom {
    margin-top: 5px;
    margin-bottom: 20px;
    display: flex;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    flex-direction: column;
    justify-content: flex-start;
    line-height: 18px;

    .responsibilitySymbol {
      display: flex;
      flex-direction: row;
    }
  }
}
