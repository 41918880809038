@font-face {
  font-family: "Digital-7";
  src: url(../../assets/fonts/digital-7.ttf) format("truetype");
}
.navbarContainer {
  .clockContainer {
    border-radius: 5px;
    background: #0d1621;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 10px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
  }

  .calendar {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 33px;
    padding: 0 10px;
    padding-top: 10px;
  }

  .row {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .countdown {
    font-size: 12px;
  }

  .hour {
    flex: 1;
    font-size: 35px;
    margin: 0;
    padding: 0;
    top: 0;
  }

  .ampm {
    align-self: flex-end;
    font-size: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 10px;
    margin-bottom: 5px;
  }

  .digital {
    display: inline-block;
    position: relative;
    font-family: "Digital-7";
  }

  .digital :first-child {
    width: 100%;
    position: absolute;
    color: #242a32;
  }

  .digital :last-child {
    position: relative;
    color: #ebebeb;
  }

  .digital p {
    margin: 0;
  }

  // button {
  //   font-size: 1.2rem;
  //   border-radius:5px;
  //   padding: 10px;
  //   margin-bottom: 10px;
  // }

  .fa {
    margin-left: 15px;
    font-size: 1.1rem;
  }

  a {
    font-size: 1.1rem;
    text-decoration: none;
  }

  .message {
    background: #da9090;
    border: 2px solid rgb(255, 115, 0);
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    font-size: 1.5rem;
    font-family: monospace, Arial, Helvetica, sans-serif;
    text-align: center;
  }

  // @media (max-width: 770px) {

  // .calendar, .ampm {
  //   font-size: 0.7rem;
  // }

  // .hour {
  //   font-size: 12rem;
  // }

  // .clock {
  //   padding: 5px;
  // }

  // .countdown {
  //   font-size: 4rem;
  // }

  // }
}
