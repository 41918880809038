.homeContainer {
  padding: 20px;
  overflow: hidden;
  height: 100%;

  .cardContainer {
    max-width: 350px;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: center !important;
    align-items: center;
    height: fit-content;

    .userInfo {
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .iconBtn {
        font-size: 40px !important;
        color: black;
        min-width: 0px;
        width: fit-content;
        margin-right: 10px;
        padding: 1px;
        margin-top: 10px;
      }

      .imgBtn {
        height: 34px;
        width: 34px;
        color: black;
        padding: 0px;
        margin-right: 10px;
        border-radius: 7px;
      }

      .userIcon {
        border-radius: 5px;
        width: 100%;
      }

      .userDescText {
        h2 {
          margin-bottom: 0px;
        }
        width: 100%;
        text-align: left;
      }
    }
  }

  .sloganContainer {
    color: #00ff00;
    background-color: black !important;
    height: 100% !important;
    flex-grow: 1;

    .slogan {
      margin: 20px;
      justify-content: flex-start;
      text-align: flex-start;
      display: flex;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
