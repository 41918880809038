.projectsContainer {
  overflow: hidden;
  height: 100%;
  width: 100%;

  .projectHeader {
    margin-top: 30px;
    margin-left: 20px;
    background: linear-gradient(45deg, #553c9a, #ee4b2b);
    font-size: 25px;
    font-weight: bold;
    width: auto;
    flex-shrink: 1;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .gridContainer {
    overflow: hidden;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center !important;

    .cardContainer {
      width: auto;
      height: fit-content;
      justify-content: center;
      align-items: center;
      text-align: flex-start;
      padding: 20px;
      flex-grow: 1;
    }
  }
}
